export class Account {
  metadata?: Metadata = new Metadata();
  data?: Data = new Data();
}

export class Metadata {
  lang?: string = '';
  operation?: string = '';
  asset?: string = '';
  id?: string = '';
}

export class Data {
  token?: string = '';
}
