import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Vehicle } from '../../shared/utils/class/getters/vehicle';
import { SetVehicle } from '../../shared/utils/class/setters/setVehicle';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getVehicles(lang: string, pageSize: number | null, pageNumber: number): Observable<Vehicle> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    return this.http.get<Vehicle>(`${this.url}vehicles?lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}`);
  }

  getVehiclesDoc(): Observable<Vehicle> {
    var select = "vehicleID,meansType,files,assetType,registeredBy,owner";
    return this.http.get<Vehicle>(`${this.url}vehicles?select=${select}`);
  }

  getVehicleById(id: string): Observable<Vehicle> {
    return this.http.get<Vehicle>(`${this.url}vehicles?_id=${id}`);
  }

  deleteVehicle(vehicleId: string): Observable<Vehicle> {
    return this.http.delete<Vehicle>(`${this.url}vehicles/${vehicleId}`);
  }

  setVehicle(newVehicle: SetVehicle): Observable<SetVehicle> {
    return this.http.put<SetVehicle>(this.url + 'vehicles', newVehicle);
  }

  getVehicleDocsByCompanyVAT(lang: string, pageSize: number | null, pageNumber: number, companyVAT: string): Observable<Vehicle> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    //types de empresas a devolver
    var select = "vehicleID,meansType,files";
    return this.http.get<Vehicle>(`${this.url}vehicles?lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}&select=${select}&registeredBy.organization.code=${companyVAT}`);
  }

  getVehicleByVehicleID(vehicleID: string): Observable<Vehicle> {
    return this.http.get<Vehicle>(`${this.url}vehicles?vehicleID=${vehicleID}`);
  }
}
