import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DriverClass } from '../../shared/utils/class/getters/driverClass';
import { SetDriver } from '../../shared/utils/class/setters/setDriver';

@Injectable({
  providedIn: 'root'
})
export class DriversService {

  url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getUsers(lang: string, pageSize: number | null, pageNumber: number): Observable<DriverClass> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    return this.http.get<DriverClass>(`${this.url}users?lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}`);
  }

  getUserByEmail(email: string): Observable<DriverClass> {
    return this.http.get<DriverClass>(`${this.url}users?email=${email}`);
  }

  getUserById(id: string): Observable<DriverClass> {
    return this.http.get<DriverClass>(`${this.url}users?_id=${id}`);
  }

  getDrivers(lang: string, pageSize: number | null, pageNumber: number): Observable<DriverClass> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    return this.http.get<DriverClass>(`${this.url}users?roles=driver&lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}`);
  }

  getDriversDoc(): Observable<DriverClass> {
    var select = "fullName,userId,driver.files,companyName,assetType,companyVAT";
    return this.http.get<DriverClass>(`${this.url}users?roles=driver&select=${select}`);
  }

  getDriverById(id: string): Observable<DriverClass> {
    return this.http.get<DriverClass>(`${this.url}users?roles=driver&_id=${id}`);
  }

  setDriver(newDriver: SetDriver): Observable<SetDriver> {
    return this.http.put<SetDriver>(this.url + 'auth/profile', newDriver);
  }

  deleteDriver(driverId: string): Observable<DriverClass> {
    return this.http.delete<DriverClass>(`${this.url}users/${driverId}`);
  }

  getDriversDocsByCompanyVAT(lang: string, pageSize: number | null, pageNumber: number, companyVAT: string): Observable<DriverClass> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    //types de empresas a devolver
    var select = "fullName,userId,driver.files";
    var roles = 'driver';
    return this.http.get<DriverClass>(`${this.url}users?lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}&roles=${roles}&select=${select}&companyVAT=${companyVAT}`);
  }

  getAllProfiles(): Observable<any>{
    return this.http.get<any>(`${this.url}users?metadata=vigiaRoles`);
  }

  getDriverByUserId(userId: string): Observable<DriverClass> {
    return this.http.get<DriverClass>(`${this.url}users?roles=driver&userId=${userId}`);
  }
}
