<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
  <div class="block-header">
    <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
            <h2>{{ pageTitle }}</h2>
            <button class="btn btn-primary btn-icon mobile_menu" type="button"><i class="zmdi zmdi-sort-amount-desc"></i></button>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12">
            <button class="btn btn-primary btn-icon float-end right_icon_toggle_btn" type="button"><i class="zmdi zmdi-arrow-right"></i></button>
            <button  type="submit" [disabled]="myForm.invalid" class="btn btn-success btn-icon float-end"><i class="zmdi zmdi-check"></i></button>
        </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-md-12">
          <div class="card">
            <div class="header">
              <h2><strong>Configuración</strong> de la empresa</h2>
            </div>
            <div class="body">
              <div class="row align-items-center">
                <div class="col-lg-3 col-md-12 text-center">
                  <img src="../../../../../../assets/images/maintanance.svg" class="img-fluid rounded" alt="...">
                  <p>Cambiar imagen</p>
                </div>
                <div class="col-lg-9 col-md-12">
                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group mb-3">
                        <input type="text" class="form-control" placeholder="Nombre de la empresa" name="name" formControlName="name">
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group mb-3">
                        <input type="text" class="form-control" placeholder="CIF" name="cif" formControlName="cif">
                      </div>
                    </div>
<!--                     <div class="col-lg-4 col-md-12">
                      <div class="form-group mb-3">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Tipo empresa</mat-label>
                          <mat-select name="tipoEmpresa" formControlName="tipoEmpresa">
                            <mat-option value="private">Privada</mat-option>
                            <mat-option value="public">Pública</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div> -->
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group mb-3">
                        <input type="text" class="form-control" placeholder="Teléfono" name="phone" formControlName="phone">
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group mb-3">
                        <input type="text" class="form-control" placeholder="Correo electrónico" name="email" formControlName="email">
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <div class="form-group mb-3">
                        <input type="text" class="form-control" placeholder="Sitio web (opcional)" name="web" formControlName="web">
                      </div>
                    </div>
                    <div *ngIf="controlUserVisible" class="col-md-12 mt-2">
                      <p class="fw-bolder">Instalación a las que solicita acceso</p>
                      <div *ngFor="let instalacion of instalaciones.data" class="checkbox">
                        <input [id]="'procheck' + instalacion.organization.code" type="checkbox" (click)="checkboxChange(instalacion)">
                        <label [for]="'procheck' + instalacion.organization.code">{{ instalacion.organization.name }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
              <div class="header">
                  <h2><strong>Dirección</strong> de la empresa</h2>
              </div>
              <div class="body">
                  <div class="row clearfix">
                    <div class="col-lg-12 col-md-12">
                      <div class="form-group mb-3">
                        <input type="text" class="form-control" placeholder="Dirección" name="direccion" formControlName="direccion">
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group mb-3">
                        <input type="text" class="form-control" placeholder="Localidad" name="localidad" formControlName="localidad">
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group mb-3">
                        <input type="text" class="form-control" placeholder="Provincia" name="provincia" formControlName="provincia">
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="form-group mb-3">
                        <input type="text" class="form-control" placeholder="Código postal" name="cp" formControlName="cp">
                      </div>
                    </div>
                  </div>
              </div>
          </div>

          <div class="card">
            <div class="header">
                <h2><strong>Autorizaciones</strong> de la empresa</h2>
            </div>
            <div class="body">
                <div class="row clearfix">
                  <div class="checkbox">
                    <input formControlName="auth1" id="procheck1" type="checkbox">
                    <label for="procheck1">Autorización volcado de datos desde REAT</label>
                </div>
                <div class="checkbox">
                    <input id="procheck2" formControlName="auth2" type="checkbox">
                    <label for="procheck2">Al efectuar el registro, usted confirma que acepta los Términos y Condiciones del servicio.</label>
                </div>
                <div class="checkbox">
                  <input id="procheck3" formControlName="auth3" type="checkbox">
                  <label for="procheck3">Al efectuar el registro, usted confirma que acepta la Política de Privacidad del servicio y que sus datos sean recogidos de REAT.</label>
              </div>
                </div>
            </div>
          </div>
    </div>
    </div>

  </div>
  </form>
