import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  showBasicMessage(message: string) {
    Swal.fire(message);
  }

  showWithTitleMessage(title: string, message: string) {
    Swal.fire(title, message);
  }

  showSuccessMessage(type: string, entity: string) {
    let action = '';
    let message = '';

    switch (type) {
      case 'add':
        action = '¡Creado!';
        message = `El ${entity} se ha creado correctamente.`;
        break;
      case 'modify':
        action = '¡Modificado!';
        message = `El ${entity} se ha modificado correctamente.`;
        break;
      case 'delete':
        action = '¡Eliminado!';
        message = `El ${entity} se ha eliminado correctamente.`;
        break;
      default:
        break;
    }

    // Ajustar el mensaje si la entidad es "oficina"
    if (entity === 'oficina') {
      message = `La ${entity} se ha ${type === 'add' ? 'creado' : type === 'modify' ? 'modificado' : 'eliminado'} correctamente.`;
    }

    Swal.fire(action, message, 'success');
  }

  showErrorMessage(type: string, entity: string, error: string) {
    let action = '¡Error!';
    let message = 'Error desconocido' + error;

    switch (type) {
      case 'cancel':
        action = '¡Cancelado!';
        message = 'Se ha cancelado la acción.';
        break;
      case 'add':
        message = `Ha habido un error en la creación de ${entity}: ` + error;
        break;
      case 'modify':
        message = `Ha habido un error en la modificación de ${entity}: `  + error;
        break;
      case 'delete':
        message = `Ha habido un error en la eliminación de ${entity}: `  + error;
        break;
      case 'get':
        message = `Ha habido un error en la obtención de ${entity}: ` + error;
        break;
      case 'logout':
        message = 'Ha habido un error al desconectarse: ' + error;
        break;
      case 'login':
        message = 'Ha habido un error al conectarse: ' + error;
        break;
      default:
        break;
    }

    Swal.fire(action, message, 'error');
  }

  showConfirmMessage(): boolean {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Una vez eliminado, no podrás recuperarlo.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Estoy seguro',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return false;
      }
      return false;
    });
    return false;
  }

  showAutoCloseTimerMessage(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false
    });
  }

}
