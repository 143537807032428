import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    // Verificar si el token existe en el almacenamiento local
    const token = localStorage.getItem('token');
    if (token) {
      return true; // Permitir el acceso a la ruta protegida
    } else {
      this.router.navigate(['/login']); // Redirigir a la página de inicio de sesión
      return false; // Denegar el acceso a la ruta protegida
    }
  }
}
