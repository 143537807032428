import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OfficeClass } from '../../shared/utils/class/getters/officeClass';
import { Office } from '../../shared/utils/class/getters/vehicle';
import { Oficina } from '../../shared/utils/class/setters/oficina';
import { IsDocumentationValidClass } from 'src/app/shared/utils/class/getters/isDocumentationValidClass';

@Injectable({
  providedIn: 'root'
})
export class OfficesService {

  url = environment.apiUrl;

  //variables de login por defecto hasta que tengamos un login de verdad XD
  email = "infoporttest@infoport.app";
  password = "Infoport321";

  constructor(private http: HttpClient) { }

  //de momento el token se queda en los parametros del metodo, andrey sabe como hacerlo
  //con un archivo que te hace que cada peticion http ya tengo el bearer. (chat gpt xd)
  getOffices(lang: string, pageSize: number | null, pageNumber: number): Observable<OfficeClass> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    //types de empresas a devolver
    var types = "[portAuthority,portTerminal,roadHaulier]";
    //var roles = 'logisticsFacility';
    return this.http.get<OfficeClass>(`${this.url}offices?lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}&types=${types}`);
  }

  getLogisticAuthorithyOffices(lang: string, pageSize: number | null, pageNumber: number): Observable<OfficeClass> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    //types de empresas a devolver
    var types = "[portAuthority,portTerminal]";
    //var roles = 'logisticsFacility';
    return this.http.get<OfficeClass>(`${this.url}offices?lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}&types=${types}`);
  }

  getOfficeByCompanyVAT(companyVAT: string): Observable<OfficeClass> {
    return this.http.get<OfficeClass>(`${this.url}offices?organization.code=${companyVAT}`);
  }

  getOfficesByType(type: string,lang: string, pageSize: number | null, pageNumber: number): Observable<OfficeClass> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    //types de empresas a devolver
    var types = type;
    return this.http.get<OfficeClass>(`${this.url}offices?lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}&types=${types}`);
  }

  getLogisticOffices(lang: string, pageSize: number | null, pageNumber: number): Observable<OfficeClass> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    //types de empresas a devolver
    var types = "[portTerminal]";
    var roles = 'logisticsFacility';
    return this.http.get<OfficeClass>(`${this.url}offices?lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}&roles=${roles}&types=${types}`);
  }

  getOfficeById(id: string): Observable<OfficeClass> {
    return this.http.get<OfficeClass>(`${this.url}offices?_id=${id}`);
  }

  getOfficeByID(ID: string): Observable<OfficeClass> {
    return this.http.get<OfficeClass>(`${this.url}offices?ID=${ID}`);
  }

  setOffices(newOffice: Oficina): Observable<Oficina> {
    return this.http.post<Oficina>(this.url + 'offices', newOffice);
  }

  updateOffices(newOffice: Oficina): Observable<Oficina> {
    return this.http.put<Oficina>(this.url + 'offices', newOffice);
  }

  deleteOffices(officeId: string): Observable<OfficeClass> {
    return this.http.delete<OfficeClass>(`${this.url}offices/${officeId}`);
  }

  getCompanyDocsById(id: string): Observable<OfficeClass> {
    //types de empresas a devolver
    var select = "organization,office,files";
    return this.http.get<OfficeClass>(`${this.url}offices?_id=${id}&select=${select}`);
  }

  getValidity(assetId: string, destination: string) : Observable<IsDocumentationValidClass> {
    return this.http.get<IsDocumentationValidClass>(`${this.url}offices?metadata=isDocumentationValid&assetID=${assetId}&destinationVAT=${destination}`);
  }

}
