import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentationType } from '../../shared/utils/class/getters/documentationType';
import { SetDocumentationType } from '../../shared/utils/class/setters/setDocumentationType';
import { SetDocumentationFile } from '../../shared/utils/class/setters/setDocumentationFile';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {

  url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAllDocumentationTypes(lang: string, pageSize: number | null, pageNumber: number): Observable<DocumentationType> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    return this.http.get<DocumentationType>(`${this.url}masterdata?type=documentType&lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}`);
  }

  getDocumentationTypes(classifiers: string,lang: string, pageSize: number | null, pageNumber: number): Observable<DocumentationType> {
    //si es null le pasamos vacio, sino el numero
    const pageSizeParam = pageSize !== null ? `&pageSize=${pageSize}` : '';
    return this.http.get<DocumentationType>(`${this.url}masterdata?type=documentType&classifiers=${classifiers}&lang=${lang}${pageSizeParam}&pageNumber=${pageNumber}`);
  }

  getDocumentationTypeById(id: string): Observable<DocumentationType> {
    return this.http.get<DocumentationType>(`${this.url}masterdata?_id=${id}`);
  }

  getDocumentationTypeByCode(code: string): Observable<DocumentationType> {
    return this.http.get<DocumentationType>(`${this.url}masterdata?code=${code}`);
  }

  setDocumentationType(newType: SetDocumentationType): Observable<SetDocumentationType> {
    return this.http.post<SetDocumentationType>(this.url + 'masterdata', newType);
  }

  updateDocumentationType(newType: SetDocumentationType): Observable<SetDocumentationType> {
    return this.http.post<SetDocumentationType>(this.url + 'masterdata', newType);
  }

  deleteDocumentationType(documentId: string): Observable<DocumentationType> {
    return this.http.delete<DocumentationType>(`${this.url}masterdata/${documentId}`);
  }

  //documentation files methods
  setDocumentationFile(newFile: any): Observable<SetDocumentationFile> {
    return this.http.post<SetDocumentationFile>(this.url + 'uploadFiles', newFile);
  }

  //download a file
  getDocumentationFile(id: string): Observable<any>{
    // Configura las opciones de cabecera para indicar que se espera una respuesta de tipo blob (archivo binario)
    const httpOptions = {
      responseType: 'blob' as 'json' // responseType: 'blob' especifica que se espera una respuesta de tipo blob
    };
    return this.http.get<any>(`${this.url}files?select=FILE INLINE&_id=${id}`, httpOptions);
  }

  //delete file
  deleteDocumentationFile(documentId: string): Observable<SetDocumentationFile> {
    return this.http.delete<SetDocumentationFile>(`${this.url}files/${documentId}`);
  }
}
