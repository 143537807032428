import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Obtener el token del almacenamiento local
    const token = localStorage.getItem('token');

    // Clonar la solicitud y agregar el encabezado de autenticación si hay un token
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    // Continuar con la solicitud
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (error: any) => {
          // Verificar si la respuesta tiene el código de estado 401 (Unauthorized)
          if (error.status === 401) {
            // El token ha expirado o es inválido, redirigir al componente de inicio de sesión
            this.router.navigate(['/login']);
            // También puedes mostrar un mensaje de error al usuario si lo deseas
          }
        }
      )
    );
  }
}
