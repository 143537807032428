import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OfficesService } from 'src/app/services/apis/offices.service';
import { BusinessRelation, OfficeClass, Daum } from 'src/app/shared/utils/class/getters/officeClass';
import { Oficina } from 'src/app/shared/utils/class/setters/oficina';
import { SweetAlertService } from '../../services/apis/sweet-alerts.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  pageTitle: string = '';
  data: any;

  myForm!: FormGroup;
  controlUserVisible: boolean = false;
  instalacionesEmpresa: BusinessRelation[] = [];
  instalaciones = new OfficeClass();

  constructor(
    private fb: FormBuilder,
    private titleService: Title,
    private route: ActivatedRoute,
    private officesService: OfficesService,
    private alerts: SweetAlertService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => this.data = data);
    this.pageTitle = this.data.pageTitle;
    this.titleService.setTitle('VIGIA | ' + this.pageTitle);

    this.myForm = this.fb.group({
      name: ['', Validators.required],
      cif: ['', Validators.required],
      tipoEntidad: ['', Validators.required],
      tipoEmpresa: [''],
      phone: ['', Validators.required],
      email: [''], //email: ['', [Validators.required, Validators.email]],
      web: [''],
      direccion: ['', Validators.required],
      localidad: ['', Validators.required],
      provincia: ['', Validators.required],
      cp: ['', Validators.required],
    });

    this.officesService.getLogisticOffices('en', null, 1).subscribe(
      (response) => {
        console.log('Offices:', response);

        this.instalaciones = response;

      },
      (error) => {
        this.alerts.showErrorMessage("get", "oficina", error);
      }
    );
  }

  checkboxChange(checkbox: Daum) {
    const businessRelation: BusinessRelation = {
      ID: checkbox.ID,
      role: 'logisticsFacility',
      roles: ["logisticsFacility"],
      types: checkbox.types,
      organization: checkbox.organization,
      office: checkbox.office,
      address: checkbox.address,
      contacts: checkbox.contacts
    };

    const index = this.instalacionesEmpresa.findIndex(instalacion => instalacion.ID === checkbox.ID);

    if (index !== -1) {
      this.instalacionesEmpresa.splice(index, 1);
    } else {
      this.instalacionesEmpresa.push(businessRelation);
    }

    console.log(this.instalacionesEmpresa);
  }

  onSubmit() {
    if (this.myForm.valid) {
      console.log(this.myForm.value);

      //prueba set
      var oficina = new Oficina();
      oficina.metadata = { lang: "es" };
      oficina.data = {};
      oficina.data.organization = {
        code: this.myForm.value.cif,
        name: this.myForm.value.name
      };
      oficina.data.office = {
        code: this.myForm.value.cp,
        name: this.myForm.value.provincia
      };
      oficina.data.address = {
        country: { code: "ES", name: "ESPAÑA" },
        street: this.myForm.value.direccion,
        postalCode: this.myForm.value.cp,
        city: this.myForm.value.localidad,
        province: this.myForm.value.provincia
      };
      oficina.data.types = [];
      oficina.data.types[0] = this.myForm.value.tipoEntidad;
      oficina.data.contacts = [];
      oficina.data.contacts[0] = {
        type: "vgm",
        phone: this.myForm.value.phone
      };
      oficina.data.services = [];
      oficina.data.services[0] = "vigia";
      oficina.data.roles = [];
      oficina.data.roles.push("roadHaulier");
      oficina.data.headquarters = true;
      oficina.data.requirements = [];
      // inicializa los campos necesarios en Data y Balance
      oficina.data.codes = [];
      oficina.data.remarks = [];
      oficina.data.businessRelations = this.instalacionesEmpresa;
      oficina.data.scales = [];
      oficina.data.requestedServices = [];
      oficina.data.requestedRoles = [];

      console.log(oficina)

      this.officesService.setOffices(oficina).subscribe(
        (response)=> {
          this.alerts.showSuccessMessage("add", "oficina");
          console.log(response.data?._id)
        },
        (error)=>{
          this.alerts.showErrorMessage("modify", "oficina", error);
        }
      );
    }
  }

}
