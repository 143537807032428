import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AccountService } from 'src/app/services/apis/account.service';
import { OfficesService } from 'src/app/services/apis/offices.service';
import { Account } from 'src/app/shared/utils/class/account';
import { OfficeClass } from 'src/app/shared/utils/class/getters/officeClass';
import { Router } from '@angular/router';
import { DriversService } from '../../services/apis/drivers.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  pageTitle: string = '';
  data: any;
  myForm!: FormGroup;
  offices = new OfficeClass();
  account = new Account();
  error: string = "";

  constructor(
    private fb: FormBuilder,
    private titleService: Title,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private driversService: DriversService,
    private officesService: OfficesService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.route.data.subscribe(data => this.data = data);
    this.pageTitle = this.data.pageTitle;
    this.titleService.setTitle('VIGIA | ' + this.pageTitle);

    this.myForm = this.fb.group({
      mail: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (this.myForm.valid) {

      //prueba set
      var form = this.myForm.value

      var mail = form.mail;
      var password = form.password;

      this.accountService.login(mail,password).subscribe(
        (response) => {
          console.log(response)
          this.error = "";

          // Guardar los datos en el almacenamiento local
          if(response.data?.token != null){
            localStorage.setItem('token', response.data?.token);
            //OBTENER el id del usuario para luego hacer getUserById para lo que se necesite
            this.driversService.getUserByEmail(mail).subscribe(
              (response)=> {
                console.log(response.data[0])

                localStorage.setItem('roles', JSON.stringify(response.data[0].roles))
                localStorage.setItem('userId', response.data[0]._id);
                localStorage.setItem('userCompanyVAT', response.data[0].companyVAT);
                localStorage.setItem('userCompanyName', response.data[0].companyName);
                //get the id of the office by passing his companyVAT
                this.officesService.getOfficeByCompanyVAT(response.data[0].companyVAT).subscribe(
                  (response)=>{
                    console.log('officeId', response.data[0]._id)
                    localStorage.setItem('officeId', response.data[0]._id);

                    this.router.navigate(['/pages/home']);
                  },
                  (error) =>{}
                );
              },
              (error)=>{}
            );
          }
        },
        (error)=>{
          this.error = 'Error en la petición: ' + error;
        }
      );
    }
  }

}
