export class OfficeClass {
  metadata: Metadata = new Metadata();
  data: Daum[] = [];
}

export class Metadata {
  asset: string= '';
  select: string= '';
  action: string= '';
  count = 0;
  pageSize = 0;
  pageNumber = 0;
  start = 0;
  firstPage: boolean= false;
  lastPage: boolean= false;
  lang: string= '';
}

export class Daum {
  _id: string= '';
  createdAt: string= '';
  updatedAt: string= '';
  version: string= '';
  reportedByID: string= '';
  reportedByRole: string= '';
  assetType: string= '';
  userId: string= '';
  ID: string= '';
  organization = new Organization();
  office = new Office();
  address = new Address();
  types: string[] = [];
  codes: string[] = [];
  contacts: Contact[] = [];
  bcEnrollmentID: string= '';
  bcEncryptedWallet = new BcEncryptedWallet();
  bcMSP: string= '';
  remarks: any[] = [];
  balance = new Balance();
  requestedRoles: any[] = [];
  businessRelations: BusinessRelation[] = [];
  scales: any[] = [];
  position = new Position();
  services: any[] = [];
  requestedServices: any[] = [];
  roles: any[] = [];
  files: File[] = [];
  webhooks: any[] = [];
  inBC: boolean= false;
  hasAdmin: boolean= false;
  requirements: string[] = [];
}

export class BusinessRelation {
  ID: string= '';
  role: string= '';
  roles: string[] = [];
  types: string[] = [];
  organization = new Organization();
  office = new Office();
  address = new Address();
  contacts: Contact[] = [];
}

export class Organization {
  code: string= '';
  name: string= '';
}

export class Office {
  code: string= '';
  name: string= '';
}

export class Address {
  street: string= '';
  city: string= '';
  postalCode: string= '';
  province: string= '';
  country = new Country();
  equals: any;
}

export class Country {}

export class Contact {
  type: string= '';
  phone: string= '';
  email: string= '';
}

export class BcEncryptedWallet {
  iv: string= '';
  encryptedData: string= '';
}

export class Balance {
  currency = new Currency();
}

export class Currency {}

export class Position {
  coordinates: any[] = [];
}


export class File {
  aliases: string[]= [];
  attributes: Attributes = new Attributes();
  filename: string = '';
  _id: string = '';
  originalname: string= '';
  assetName: string= '';
  assetType: string = '';
  contentType: string= '';
  uploadDate: string= '';
}

export class Attributes {
  visibility: string= '';
  documentType: string= '';
  validity: string= '';
  actions: Actions = new Actions();
  remarks?: string= '';
}

export class Actions {
  validatedBy: ActionParam = new ActionParam();
  acceptedBy: ActionParam = new ActionParam();
  rejectedBy: ActionParam = new ActionParam();
}

export class ActionParam {
  executor: string= '';
  updatedAt: string= '';
}
