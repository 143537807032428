import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/authGuard';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';


const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'landing-page',
    loadChildren: () => import('./landing-page/landing-page.module')
      .then(m => m.LandingPageModule),
  },
  { path: 'login', component: LoginComponent, data: { pageTitle: 'Inicio sesión' } },
  { path: '', component: LoginComponent, data: { pageTitle: 'Inicio sesión' } }, // borrar esto si tenemos landing page y redirigirlo a landing page
  { path: 'register', component: RegisterComponent, data: { pageTitle: 'Registro' } },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
