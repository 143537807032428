import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Account } from '../../shared/utils/class/account';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<Account> {
    console.log(this.url+'auth/login');
    const body = { email, password };
    return this.http.post<Account>(this.url + 'auth/login', body);
  }

  //el logout todavía no esta porque no esta termiando por Borja
  logout(): Observable<Account> {
    console.log(this.url + 'auth/logout');
    const body = {
      lang: 'es',
      operator: 'LOGOUT',
      assets: ''
    };
    const options = {
      body: JSON.stringify(body)
    };
    return this.http.delete<Account>(this.url + 'auth/logout', options);
  }



}
