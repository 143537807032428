<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
  <div class="authentication">
    <div class="container column">
        <div class="row">
            <div class="col-lg-4 col-sm-12">
                <div class="card auth_form">
                    <div class="header">
                        <img class="logo" src="assets/images/logo.svg" alt="">
                        <h5>Acceso a VIGIA</h5>
                    </div>
                    <div class="body">
                        <div class="input-group mb-3">
                            <input type="email" class="form-control" name="mail" formControlName="mail" placeholder="Correo electrónico">
                        </div>
                        <div class="input-group mb-3">
                            <input type="password" class="form-control" name="password" formControlName="password" placeholder="Contraseña">
                        </div>
                        <div *ngIf="error" class="error-message text-danger">
                          {{ error }}
                        </div>
                        <div class="checkbox">
                            <input id="remember_me" type="checkbox">
                            <label for="remember_me">Recordarme</label>
                        </div>
                        <button type="submit" [disabled]="myForm.invalid" class="btn btn-primary btn-block waves-effect waves-light">Iniciar sesión</button>
                        <div class="signin_with mt-3">
                            <p [routerLink]="'./../register'" class="mb-0">o registrarse</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-sm-12">
                <div class="card">
                    <img src="assets/images/signin.svg" alt="Sign In" class="image-fluid"/>
                </div>
            </div>
        </div>

        <footer style="height: 50px;">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <img style="height: 50px;" src="assets/images/logo_AVI.png" alt="Logo AVI" class="image-fluid">
              </div>
            </div>
          </div>
        </footer>
    </div>


  </div>
</form>
