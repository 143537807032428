export class Oficina {
  metadata: Metadata = new Metadata();
  data: Data = new Data();
}

export class Metadata {
  lang: string = "es";
}

export class Data {
  _id?: string = "";
  organization?: Organization = new Organization();
  office?: Office = new Office();
  address?: Address = new Address();
  types?: string[] = [];
  codes?: any[] = [];
  remarks?: any[] = [];
  contacts?: Contact[] = [];
  balance?: Balance = new Balance();
  businessRelations?: any[] = [];
  scales?: any[] = [];
  position?: Position = new Position();
  services?: string[] = [];
  requestedServices?: any[] = [];
  roles?: string[] = [];
  requestedRoles?: any[] = [];
  files?: File[] = [];
  webhooks?: any[] = [];
  headquarters?: boolean = false;
  requirements?: string[] = [];
  ID?: string;
}

export class Organization {
  code?: string = "";
  name?: string = "";
}

export class Office {
  code?: string = "";
  name?: string = "";
}

export class Address {
  country?: Country = new Country();
  street?: string = "";
  postalCode?: string = "";
  city?: string = "";
  province?: string = "";
}

export class Country {
  code?: string = "";
  name?: string = "";
}

export class Contact {
  type?: string = "";
  phone?: string = "";
  email? : string = "";
}

export class Balance {
  currency?: Currency = new Currency();
}

export class Currency {}

export class Position {
  coordinates?: any[] = [];
}

export class File {
  aliases: string[]= [];
  attributes: Attributes = new Attributes();
  filename: string = '';
  originalname: string= '';
  assetName: string= '';
  assetType: string = '';
  contentType: string= '';
  uploadDate: string= '';
  _id: string = '';
}

export class Attributes {
  visibility: string= '';
  documentType: string= '';
  validity: string= '';
  actions: Actions = new Actions();
  remarks?: string= '';
}

export class Actions {
  validatedBy: ActionParam = new ActionParam();
  acceptedBy: ActionParam = new ActionParam();
  rejectedBy: ActionParam = new ActionParam();
}

export class ActionParam {
  executor: string= '';
  updatedAt: string= '';
}

